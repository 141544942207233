import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFormInput } from 'utilities';
import { useSelector, useDispatch } from 'react-redux';
import { updateAccount } from './actions';

import {
  InputGroup,
  InputText,
  Button,
  Spacer,
  Loader,
  InputButtonGroup,
  InputButtonGroupOption,
  InputError,
  Row,
  PriceBox,
} from 'modules/components';

export default function AccountForm() {
  const dispatch = useDispatch();
  const { handleSubmit, errors, register } = useForm();
  const isLoading = useSelector((state) => state.account.requested);
  const user = useSelector((state) => state.user);
  const account = useSelector((state) => state.account);
  const accountType = useSelector((state) => state.user.accountType);
  const accountStatus = useSelector((state) => state.user.accountStatus);
  const firstname = useFormInput(user.firstname);
  const lastname = useFormInput(user.lastname);
  const email = useFormInput(user.email);
  const [country, setCountry] = useState(account.country);
  const city = useFormInput(account.city);
  const school = useFormInput(account.school);
  const street = useFormInput(account.street);
  const zip = useFormInput(account.zip);
  const accountHolder = useFormInput(account.accountHolder);
  const iban = useFormInput(account.iban);
  const bic = useFormInput(account.bic);
  const [paymentType, setPaymentType] = useState(account.paymentType);

  function onSubmit() {
    dispatch(
      updateAccount(
        firstname.value,
        lastname.value,
        email.value,
        accountStatus,
        country.value,
        school.value,
        street.value,
        zip.value,
        city.value,
        accountHolder.value,
        paymentType,
        iban.value,
        bic.value
      )
    );
  }

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <section>
        <h1>Account: {accountType}</h1>
        <div className="flex">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Nutzerdaten/Rechnungsdaten</h2>
            {accountType === 'schule' ? (
              <InputGroup error={errors.school} className="">
                <label htmlFor="school">Schule</label>
                <InputText
                  id="school"
                  name="school"
                  type="text"
                  ref={register({
                    required: 'Bitte gib den namen deiner Schule ein.',
                  })}
                  {...school}
                />
                <InputError>{errors.school && errors.school.message}</InputError>
              </InputGroup>
            ) : (
              ''
            )}
            <Row>
              <InputGroup className="grow1" error={errors.firstname}>
                <label htmlFor="firstname">Vorname</label>
                <InputText
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoFocus
                  ref={register({
                    required: 'Bitte gib ein Vornamen ein.',
                  })}
                  {...firstname}
                />
                <InputError>{errors.firstname && errors.firstname.message}</InputError>
              </InputGroup>

              <InputGroup className="grow1" error={errors.lastname}>
                <label htmlFor="lastname">Nachname</label>
                <InputText
                  id="lastname"
                  name="lastname"
                  type="text"
                  ref={register({
                    required: 'Bitte gib ein Nachnamen ein.',
                  })}
                  {...lastname}
                />
                <InputError>{errors.lastname && errors.lastname.message}</InputError>
              </InputGroup>
            </Row>

            <InputGroup error={errors.email}>
              <label htmlFor="email">E-Mail-Adresse</label>
              <InputText
                id="email"
                name="email"
                type="text"
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Bitte gib eine gültige E-Mail-Adresse ein',
                  },
                  required: 'Bitte gib eine E-Mail-Adresse ein.',
                })}
                {...email}
              />
              <InputError>{errors.email && errors.email.message}</InputError>
            </InputGroup>

            {accountType !== 'LehrerSchule' ? (
              <>
                <Spacer />
                <h2>Anschrift</h2>

                <InputGroup>
                  <label>Kontotyp</label>
                  <InputButtonGroup>
                    <InputButtonGroupOption
                      key={'deutschland'}
                      active={'Deutschland' === country}
                      onClick={() => setCountry('Deutschland')}
                    >
                      Deutschland
                    </InputButtonGroupOption>
                    <InputButtonGroupOption
                      key={'Österreich'}
                      active={'Österreich' === country}
                      onClick={() => setCountry('Österreich')}
                    >
                      Österreich
                    </InputButtonGroupOption>
                    <InputButtonGroupOption
                      key={'schweiz'}
                      active={'Schweiz' === country}
                      onClick={() => setCountry('Schweiz')}
                    >
                      Schweiz
                    </InputButtonGroupOption>
                  </InputButtonGroup>
                </InputGroup>

                <InputGroup error={errors.street}>
                  <label htmlFor="street">Straße und Hausnummer</label>
                  <InputText
                    id="steet"
                    name="street"
                    type="text"
                    ref={register({
                      required: 'Bitte gib eine Straße und Hausnummer ein.',
                    })}
                    {...street}
                  />
                  <InputError>{errors.street && errors.street.message}</InputError>
                </InputGroup>

                <Row>
                  <InputGroup className="grow4" error={errors.zip}>
                    <label htmlFor="zip">PLZ</label>
                    <InputText
                      id="zip"
                      name="zip"
                      type="text"
                      ref={register({
                        required: 'Bitte gib eine PLZ ein.',
                        min: 3,
                        max: 7,
                      })}
                      {...zip}
                    />
                    <InputError>{errors.zip && errors.zip.message}</InputError>
                  </InputGroup>

                  <InputGroup className="grow6" error={errors.city}>
                    <label htmlFor="city">Stadt</label>
                    <InputText
                      id="city"
                      name="city"
                      type="text"
                      ref={register({
                        required: 'Bitte gib eine Stadt ein.',
                      })}
                      {...city}
                    />
                    <InputError>{errors.city && errors.city.message}</InputError>
                  </InputGroup>
                </Row>

                <Spacer />

                <h2>Zahlungsart</h2>

                <InputGroup>
                  <InputButtonGroup>
                    {/* <InputButtonGroupOption key={'sepa'} active={'sepa' === paymentType} onClick={() => setPaymentType('sepa')}>
                        SEPA Lastschrift
                      </InputButtonGroupOption> */}
                    <InputButtonGroupOption
                      key={'Rechnung'}
                      active={'Rechnung' === paymentType}
                      onClick={() => setPaymentType('Rechnung')}
                    >
                      Rechnung
                    </InputButtonGroupOption>
                  </InputButtonGroup>
                </InputGroup>

                {/* {'sepa' === paymentType && (
                    <>
                      <InputGroup error={errors.accountHolder}>
                        <label htmlFor="accountHolder">Kontoinhaber</label>
                        <InputText
                    row      id="accountHolder"
                          name="accountHolder"
                          type="text"
                          ref={register({
                            required: 'Bitte gib den Kontoinhaber ein.',
                          })}
                          {...accountHolder}
                        />
                        <InputError>{errors.accountHolder && errors.accountHolder.message}</InputError>
                      </InputGroup>

                      <InputGroup error={errors.iban}>
                        <label htmlFor="iban">IBAN</label>
                        <InputText
                          id="iban"
                          name="iban"
                          type="text"
                          ref={register({
                            validate: validateIban,
                            required: 'Bitte gib eine IBAN ein.',
                          })}
                          {...iban}
                        />
                        <InputError>{errors.iban && errors.iban.message}</InputError>
                        <InputError>{errors.iban && errors.iban.type === 'validate' && 'test'}</InputError>
                      </InputGroup>

                      <InputGroup error={errors.bic}>
                        <label htmlFor="email">BIC</label>
                        <InputText
                          id="bic"
                          name="bic"
                          type="text"
                          ref={register({
                            required: 'Bitte gib eine BIC ein.',
                          })}
                          {...bic}
                        />
                        {errors.bic && errors.bic.message}
                      </InputGroup>

                      <TermsAndConditions>
                        Mit der Angabe Ihrer IBAN und der Bestätigung dieser Zahlung ermächtigen Sie Christoph Georld
                        und unseren Zahlungsdienstleister Stripe, Ihrer Bank Anweisungen zur Belastung Ihres Kontos und
                        Ihrer Bank zur Belastung Ihres Kontos gemäß diesen Anweisungen zu senden. Sie haben Anspruch auf
                        eine Rückerstattung von Ihrer Bank gemäß den Bestimmungen und Bedingungen Ihrer Vereinbarung mit
                        Ihrer Bank. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Datum der Belastung Ihres
                        Kontos beantragt werden.
                      </TermsAndConditions>
                    </>
                  )} */}
              </>
            ) : (
              ''
            )}
            <Spacer />

            {accountStatus === 'active' ? (
              <>
                <h2>Kündigung</h2>
                <p>Für eine Kündigung schreiben sie bitte eine E-Mail an support@scholari.de.</p>
                <Spacer />
              </>
            ) : (
              ''
            )}

            {accountStatus === 'test' ? (
              <InputGroup>
                <Button type="submit">Kostenpflichtig Abonnieren</Button>
              </InputGroup>
            ) : (
              <InputGroup>
                <Button type="submit">Speichern</Button>
              </InputGroup>
            )}
          </form>

          <PriceBox accountType={accountType}></PriceBox>
        </div>
      </section>
    );
  }
}
