import * as t from './actionTypes';
import { post } from 'utilities';
import { push } from 'connected-react-router';

export function reset(email) {
  return (dispatch) => {
    dispatch({ type: t.RESET_USER_REQUESTED });

    post('/api/password-reset', { email })
      .then((data) => {
        dispatch({ type: t.RESET_USER_COMPLETE, data });
        dispatch(push('/login'));
      })
      .catch((error) => {
        dispatch({ type: t.RESET_USER_ERROR, error });
      });
  };
}
