export async function get(url = '') {
  try {
    const response = await fetch(url);
    const result = await response.json();
    if (response.ok) {
      return result;
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function post(url = '', data = {}) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function put(url, data) {
  const resp = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!resp.ok) throw new Error(resp.statusText);
  return resp.json();
}

export async function del(url) {
  const resp = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!resp.ok) throw new Error(resp.statusText);
  return resp.json();
}
