const SvgScholariLogoSmall = props => (
  <svg width="1em" height="1em" viewBox="0 0 10 35" {...props}>
    <defs>
      <symbol overflow="visible" id="scholari-logo-small_svg__b">
        <path d="M1.766-9.203h1.093c1.582 5.281 4.266 7.922 8.047 7.922 1.582 0 2.844-.43 3.781-1.297.946-.863 1.422-2.07 1.422-3.625 0-1.352-.414-2.488-1.234-3.406-.824-.926-2.086-1.743-3.781-2.454l-.969-.39c-2.523-1.02-4.48-2.176-5.875-3.469-1.398-1.289-2.094-3.098-2.094-5.422 0-1.55.36-2.945 1.078-4.187.72-1.25 1.766-2.235 3.141-2.953 1.383-.72 3.047-1.078 4.984-1.078.957 0 1.774.074 2.454.218.675.149 1.378.352 2.109.61.562.242 1.004.359 1.328.359.207 0 .367-.055.484-.172.114-.125.22-.348.313-.672h.875l.437 7.969h-1.062c-.438-2.145-1.2-3.773-2.281-4.89-1.086-1.114-2.45-1.672-4.094-1.672-1.469 0-2.656.421-3.563 1.265C7.453-25.71 7-24.633 7-23.312c0 .992.273 1.843.828 2.562a6.99 6.99 0 002.031 1.766c.79.46 1.786.933 2.985 1.422l1.281.53c2.258.938 4.008 2.071 5.25 3.391 1.25 1.325 1.875 3.102 1.875 5.329 0 2.492-.828 4.59-2.484 6.296-1.657 1.7-4.278 2.547-7.86 2.547-1.199 0-2.183-.09-2.953-.265-.762-.18-1.492-.41-2.187-.704a9.486 9.486 0 01-.532-.203 2.081 2.081 0 00-.75-.156c-.293 0-.546.086-.765.25-.219.156-.36.34-.422.547h-.922zm0 0" />
      </symbol>
    </defs>
    <use
      xlinkHref="#scholari-logo-small_svg__a"
      x={39.713}
      y={419.103}
      width="100%"
      height="100%"
      transform="translate(-322.46 -29.947)"
    />
    <use
      xlinkHref="#scholari-logo-small_svg__a"
      x={395.49}
      y={419.103}
      width="100%"
      height="100%"
      transform="translate(-322.46 -29.947)"
    />
    <use
      xlinkHref="#scholari-logo-small_svg__b"
      x={315.694}
      y={62.307}
      width="100%"
      height="100%"
      fill="#5c9a5a"
      transform="translate(-322.46 -29.947)"
    />
  </svg>
);

export default SvgScholariLogoSmall;
