import { Fragment, useState } from 'react';
import { setCategory, setSection, setAssessment } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { Spacer, Grid } from '../components';

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export default function GeneratorNavigation() {
  const dispatch = useDispatch();
  const assessmentColors = {
    'sehr gut': '#adccac',
    gut: '#c1eaae',
    befriedigend: '#e2f0bb',
    ausreichend: '#f2f2c2',
    mangelhaft: '#f9d7d0',
    ungenügend: '#eeb9ae',
    allgemein: '#e2e6e3',
  };
  const grades = useSelector((state) => state.generator.grades);
  const firstname = useSelector((state) => state.generator.firstname);
  const activeYear = useSelector((state) => state.generator.year);
  const activeGender = useSelector((state) => state.generator.gender);
  const activeCategory = useSelector((state) => state.generator.category);
  const activeSection = useSelector((state) => state.generator.section);
  const generalYear = useSelector((state) => state.generator.years.find((year) => year === 'allgemein'));

  const categories = useSelector((state) =>
    state.generator.categories
      .filter(
        (category) =>
          (category.year === activeYear || (generalYear && category.year === generalYear)) &&
          state.generator.sections.some((section) => section.category_id === category.id)
      )
      .sort((a, b) => a.sort - b.sort)
      .sort((a, b) => b.year.localeCompare(a.year))
  );
  const sections = useSelector((state) =>
    state.generator.sections
      .filter((section) => section.category_id === state.generator.category)
      .sort((a, b) => a.sort - b.sort)
  );

  const groupByGrade = groupBy('grade');

  const assessments = groupByGrade(
    useSelector((state) =>
      state.generator.assessments
        .filter(
          (assessment) =>
            assessment.section_id === activeSection &&
            (assessment.gender === activeGender || assessment.gender === 'neutral')
        )
        .sort((a, b) => a.sort - b.sort)
        .sort((a, b) => a.grade - b.grade)
        .map((assessment) => ({ ...assessment, text: assessment.text.replace(/#/g, firstname) }))
    )
  );

  const rotated = {
    transform: `rotateZ(90deg) translate(-10px, -7px)`,
    transformOrigin: 'left',
    position: 'absolute',
  };
  const [openSection, setOpenSection] = useState(0);

  return (
    <nav className="generator-navigation">
      <h2>Kartei</h2>
      <div className="kartei">
        <div onClick={() => setOpenSection(0)} className={`reiter ${openSection === 0 ? 'flex-1' : 'cursor-pointer '}`}>
          <div className="reiter-text" style={openSection !== 0 ? rotated : {}}>
            Fächer
          </div>
          <Spacer />
          {openSection === 0 && (
            <Grid>
              {categories.map((category) => (
                <div
                  key={category.id}
                  className={`
                      ${category.id === activeCategory ? 'text-green-600' : ''}
                       cursor-pointer`}
                  onClick={() => {
                    dispatch(setCategory(category.id));
                    setTimeout(() => setOpenSection(1));
                  }}
                >
                  {category.name}
                </div>
              ))}
            </Grid>
          )}
        </div>
        <div onClick={() => setOpenSection(1)} className={`reiter ${openSection === 1 ? 'flex-1' : 'cursor-pointer'}`}>
          <div className="reiter-text" style={openSection !== 1 ? rotated : {}}>
            Bereiche
          </div>
          <Spacer />
          {openSection === 1 && (
            <Grid>
              {sections.map((section) => (
                <div
                  key={section.id}
                  className={`
                    ${section.id === activeSection ? 'text-green-600' : ''} 
                    cursor-pointer`}
                  onClick={() => {
                    dispatch(setSection(section.id));
                    setTimeout(() => setOpenSection(2));
                  }}
                >
                  {section.name}
                </div>
              ))}
            </Grid>
          )}
        </div>
        <div onClick={() => setOpenSection(2)} className={`reiter ${openSection === 2 ? 'flex-1' : 'cursor-pointer'}`}>
          <div className="reiter-text" style={openSection !== 2 ? rotated : {}}>
            Beurteilungen
          </div>
          <Spacer />
          {openSection === 2 && (
            <Grid>
              {grades
                .filter((grade) => grade in assessments)
                .map((grade) => (
                  <Fragment key={grade}>
                    <div style={{ backgroundColor: assessmentColors[grade] }}>
                      {assessments[grade].map((assessment) => (
                        <div
                          key={assessment.id}
                          className="hover:text-green-600 cursor-pointer p-2"
                          onClick={() => dispatch(setAssessment(assessment.text))}
                        >
                          {assessment.text}
                        </div>
                      ))}
                    </div>
                  </Fragment>
                ))}
            </Grid>
          )}
        </div>
      </div>
    </nav>
  );
}
