const SvgScholariLogoLogin = props => (
  <svg width="218.58" height="82.228" version="1.2" viewBox="0 0 163.935 61.671" {...props}>
    <defs>
      <symbol overflow="visible">
        <path stroke="none" d="M6.11-27.5h22V0h-22zM27.405-.703v-26.094H6.781V-.703zm0 0"></path>
      </symbol>
      <symbol id="b" overflow="visible">
        <path
          stroke="none"
          d="M1.766-9.203h1.093c1.582 5.281 4.266 7.922 8.047 7.922 1.582 0 2.844-.43 3.781-1.297.946-.863 1.422-2.07 1.422-3.625 0-1.352-.414-2.488-1.234-3.406-.824-.926-2.086-1.743-3.781-2.454l-.969-.39c-2.523-1.02-4.48-2.176-5.875-3.469-1.398-1.289-2.094-3.098-2.094-5.422 0-1.55.36-2.945 1.078-4.187.72-1.25 1.766-2.235 3.141-2.953 1.383-.72 3.047-1.078 4.984-1.078.957 0 1.774.074 2.454.218.675.149 1.378.352 2.109.61.562.242 1.004.359 1.328.359.207 0 .367-.055.484-.172.114-.125.22-.348.313-.672h.875l.437 7.969h-1.062c-.438-2.145-1.2-3.773-2.281-4.89-1.086-1.114-2.45-1.672-4.094-1.672-1.469 0-2.656.421-3.563 1.265C7.453-25.71 7-24.633 7-23.312c0 .992.273 1.843.828 2.562a6.99 6.99 0 002.031 1.766c.79.46 1.786.933 2.985 1.422l1.281.53c2.258.938 4.008 2.071 5.25 3.391 1.25 1.325 1.875 3.102 1.875 5.329 0 2.492-.828 4.59-2.484 6.296-1.657 1.7-4.278 2.547-7.86 2.547-1.199 0-2.183-.09-2.953-.265-.762-.18-1.492-.41-2.187-.704a9.486 9.486 0 01-.532-.203 2.081 2.081 0 00-.75-.156c-.293 0-.546.086-.765.25-.219.156-.36.34-.422.547h-.922zm0 0"
        ></path>
      </symbol>
      <symbol id="c" overflow="visible">
        <path
          stroke="none"
          d="M11.703.438c-3.105 0-5.492-.868-7.156-2.61-1.656-1.75-2.484-4.398-2.484-7.953 0-3.664.863-6.414 2.593-8.25 1.739-1.832 4.235-2.75 7.485-2.75 1.82 0 3.332.266 4.53.797 1.208.531 2.087 1.215 2.642 2.047.562.836.843 1.734.843 2.703 0 .887-.23 1.578-.687 2.078-.461.492-1.086.734-1.875.734-.793 0-1.403-.234-1.828-.703-.418-.469-.625-1.066-.625-1.797 0-.437.062-.8.187-1.093.133-.301.219-.477.25-.532.207-.351.313-.648.313-.89 0-.469-.266-.848-.797-1.14-.531-.29-1.297-.438-2.297-.438-1.73 0-3.047.59-3.953 1.765-.906 1.168-1.36 3.293-1.36 6.375 0 3.024.485 5.266 1.454 6.735.968 1.468 2.46 2.203 4.484 2.203 1.664 0 2.914-.38 3.75-1.14.844-.77 1.555-1.743 2.14-2.923l1.11.672c-.68 1.992-1.684 3.508-3.016 4.547C16.07-.082 14.172.438 11.703.438zm0 0"
        ></path>
      </symbol>
      <symbol id="d" overflow="visible">
        <path
          stroke="none"
          d="M22.438-3.656c0 .617.066 1.078.203 1.39.132.313.406.528.812.641.406.117 1.035.172 1.89.172V0H14.829v-1.453c.758 0 1.317-.055 1.672-.172.352-.113.586-.3.703-.563.113-.269.172-.687.172-1.25v-10.546c0-1.383-.328-2.442-.984-3.172-.657-.739-1.618-1.11-2.875-1.11-1.856 0-3.356.782-4.5 2.344v12.485c0 .562.05.98.156 1.25.101.261.32.449.656.562.344.117.895.172 1.656.172V0H1.094v-1.453c.883 0 1.515-.055 1.89-.172.383-.113.633-.316.75-.61.125-.3.188-.773.188-1.421v-22.172c0-.79-.09-1.328-.266-1.61-.18-.28-.5-.421-.969-.421H1.36v-1.485l6.391-1.015h1.266v12.28h.046a13.016 13.016 0 013.61-2.25c1.289-.53 2.582-.796 3.875-.796 1.844 0 3.285.57 4.328 1.703 1.04 1.125 1.563 2.715 1.563 4.766zm0 0"
        ></path>
      </symbol>
      <symbol id="e" overflow="visible">
        <path
          stroke="none"
          d="M12.234.438c-3.523 0-6.109-.864-7.765-2.594-1.657-1.727-2.485-4.442-2.485-8.14 0-3.696.832-6.43 2.5-8.204 1.676-1.77 4.286-2.656 7.829-2.656 3.5 0 6.066.875 7.703 2.625 1.644 1.742 2.468 4.453 2.468 8.14 0 3.73-.828 6.47-2.484 8.22C18.344-.43 15.754.436 12.234.436zm0-1.75c1.696 0 2.926-.72 3.688-2.157.77-1.437 1.156-3.656 1.156-6.656 0-3.195-.398-5.54-1.187-7.031-.793-1.5-2.028-2.25-3.704-2.25-1.667 0-2.89.73-3.671 2.187-.782 1.45-1.172 3.742-1.172 6.875 0 3.149.39 5.438 1.172 6.875.78 1.438 2.02 2.156 3.718 2.156zm0 0"
        ></path>
      </symbol>
      <symbol id="f" overflow="visible">
        <path
          stroke="none"
          d="M1.094-1.453c.883 0 1.523-.063 1.922-.188.394-.132.648-.347.765-.64.114-.301.172-.758.172-1.375v-22.125c0-.82-.09-1.375-.265-1.657-.168-.28-.5-.421-1-.421H1.359v-1.485l6.47-1.015h1.234v26.703c0 .617.062 1.078.187 1.39.133.313.398.528.797.641.394.117 1.004.172 1.828.172V0H1.094zm0 0"
        ></path>
      </symbol>
      <symbol id="g" overflow="visible">
        <path
          stroke="none"
          d="M22.266-.922c-.649.375-1.344.695-2.094.953-.75.27-1.684.406-2.797.406-1.2 0-2.059-.187-2.578-.562-.512-.383-.766-1.18-.766-2.39-.992.886-2.015 1.6-3.078 2.14-1.055.54-2.226.813-3.515.813-1.586 0-2.891-.383-3.922-1.141-1.024-.758-1.532-1.844-1.532-3.25 0-2 1.063-3.754 3.188-5.266 2.125-1.508 5.078-2.601 8.86-3.281v-2.688c-.032-1.226-.352-2.148-.954-2.765-.594-.613-1.64-.922-3.14-.922-.813 0-1.524.125-2.126.375-.605.25-.906.508-.906.766 0 .261.176.57.532.922.226.273.398.511.515.718.125.2.188.477.188.828 0 .73-.227 1.309-.672 1.735-.438.43-1.024.64-1.75.64-.762 0-1.375-.234-1.844-.703-.469-.469-.703-1.082-.703-1.844 0-.968.36-1.878 1.078-2.734.719-.851 1.758-1.535 3.125-2.047 1.363-.508 2.969-.765 4.813-.765 2.343 0 4.066.484 5.171 1.453 1.102.968 1.657 2.64 1.657 5.015v9.766c0 1.148.117 1.934.359 2.36.25.417.656.624 1.219.624.289 0 .55-.046.781-.14a9.75 9.75 0 00.453-.203zM6.953-5.5c0 1.844 1.082 2.766 3.25 2.766 1.207 0 2.485-.54 3.828-1.625v-6.422c-2.43.531-4.215 1.215-5.36 2.047C7.524-7.898 6.954-6.82 6.954-5.5zm0 0"
        ></path>
      </symbol>
      <symbol id="h" overflow="visible">
        <path
          stroke="none"
          d="M14.828-21.156c1.258 0 2.192.36 2.797 1.078.602.71.906 1.578.906 2.61 0 .968-.25 1.726-.75 2.265-.5.543-1.21.812-2.125.812-.793 0-1.418-.203-1.875-.609-.449-.414-.672-.988-.672-1.719 0-.437.067-.785.204-1.047.132-.27.312-.52.53-.75.22-.238.329-.406.329-.5a.39.39 0 00-.14-.297c-.087-.093-.231-.14-.438-.14-.531 0-1.121.34-1.766 1.015-.637.68-1.195 1.586-1.672 2.72a9.339 9.339 0 00-.703 3.624v8.438c0 .648.078 1.12.235 1.422.164.293.5.496 1 .609.5.117 1.304.172 2.421.172V0H1.5v-1.453c.875 0 1.5-.063 1.875-.188.383-.132.633-.347.75-.64.125-.301.188-.758.188-1.375v-12.719c0-.79-.09-1.32-.266-1.594-.18-.281-.5-.422-.969-.422H1.766v-1.5l6.328-1.015h1.234v3.828h.094c1.289-2.719 3.094-4.078 5.406-4.078zm0 0"
        ></path>
      </symbol>
      <symbol id="i" overflow="visible">
        <path
          stroke="none"
          d="M6.516-23.844c-.856 0-1.547-.27-2.079-.812-.523-.54-.78-1.223-.78-2.047 0-.82.257-1.508.78-2.063.532-.562 1.223-.843 2.079-.843.875 0 1.578.28 2.109.843.531.555.797 1.243.797 2.063 0 .824-.281 1.508-.844 2.047-.555.543-1.242.812-2.062.812zM1.28-1.454c.875 0 1.5-.062 1.875-.187.383-.132.633-.347.75-.64.125-.301.188-.758.188-1.375v-12.719c0-.813-.086-1.348-.25-1.61-.156-.269-.485-.406-.985-.406H1.547v-1.5l6.469-1.015h1.218v17.25c0 .648.055 1.12.172 1.422.125.293.375.496.75.609.383.117 1.02.172 1.906.172V0H1.283zm0 0"
        ></path>
      </symbol>
      <clipPath id="a">
        <path d="M0 0h793.672v446.43H0zm0 0"></path>
      </clipPath>
    </defs>
    <g transform="translate(-314.434 -29.947)">
      <g clipPath="url(#a)" clipRule="nonzero" transform="translate(312.67 238.049)">
        <path
          fill="#fff"
          fillOpacity="1"
          fillRule="evenodd"
          stroke="none"
          d="M0 0h793.672v446.43H0zm0 0"
        ></path>
      </g>
      <use
        width="100%"
        height="100%"
        x="39.713"
        y="419.103"
        fill="#000"
        fillOpacity="1"
        xlinkHref="#glyph0-1"
      ></use>
      <use
        width="100%"
        height="100%"
        x="395.49"
        y="419.103"
        fill="#000"
        fillOpacity="1"
        xlinkHref="#glyph0-1"
      ></use>
      <use
        width="100%"
        height="100%"
        x="315.694"
        y="62.307"
        fill="#5c9a5a"
        fillOpacity="1"
        xlinkHref="#b"
      ></use>
      <use
        width="100%"
        height="100%"
        x="338.747"
        y="62.307"
        fill="#5c9a5a"
        fillOpacity="1"
        xlinkHref="#c"
      ></use>
      <use
        width="100%"
        height="100%"
        x="360.436"
        y="62.307"
        fill="#5c9a5a"
        fillOpacity="1"
        xlinkHref="#d"
      ></use>
      <use
        width="100%"
        height="100%"
        x="386.216"
        y="62.307"
        fill="#5c9a5a"
        fillOpacity="1"
        xlinkHref="#e"
      ></use>
      <g fill="#5c9a5a" fillOpacity="1">
        <use width="100%" height="100%" x="409.797" y="62.307" xlinkHref="#f"></use>
        <use width="100%" height="100%" x="422.775" y="62.307" xlinkHref="#g"></use>
      </g>
      <use
        width="100%"
        height="100%"
        x="445.256"
        y="62.307"
        fill="#5c9a5a"
        fillOpacity="1"
        xlinkHref="#h"
      ></use>
      <g fill="#5c9a5a" fillOpacity="1">
        <use width="100%" height="100%" x="464.306" y="62.307" fill="#5c9a5a" xlinkHref="#i"></use>
        <flowRoot
          fill="#000"
          stroke="none"
          fontFamily="sans-serif"
          fontSize="40"
          fontStyle="normal"
          fontWeight="normal"
          letterSpacing="0"
          wordSpacing="0"
          style={{ lineHeight: '1.25' }}
          xmlSpace="preserve"
        >
          <flowRegion>
            <path d="M-24.42 106.409H332.582V155.83100000000002H-24.42z"></path>
          </flowRegion>
          <flowPara></flowPara>
        </flowRoot>
        <text
          x="316.062"
          y="88.388"
          fill="#000"
          stroke="none"
          strokeWidth="0.75"
          fontFamily="Frank Ruhl Libre Medium"
          fontSize="26"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          letterSpacing="0"
          wordSpacing="0"
          xmlSpace="preserve"
          style={{ lineHeight: '1.25' }}
        >
          <tspan
            x="316.062"
            y="88.388"
            fill="#fff"
            fillOpacity="1"
            style={{}}
            strokeWidth="0.75"
            fontFamily="Frank Ruhl Libre Medium"
            fontSize="26"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
          >
            Generator
          </tspan>
        </text>
        <flowRoot
          fill="#000"
          stroke="none"
          fontFamily="sans-serif"
          fontSize="40"
          fontStyle="normal"
          fontWeight="normal"
          letterSpacing="0"
          wordSpacing="0"
          style={{ lineHeight: '1.25' }}
          xmlSpace="preserve"
        >
          <flowRegion>
            <path d="M-936.16 -145.953H337.95500000000004V159.93300000000002H-936.16z"></path>
          </flowRegion>
          <flowPara></flowPara>
        </flowRoot>
      </g>
      <text
        x="392.247"
        y="87.912"
        fill="#000"
        fillOpacity="1"
        stroke="none"
        strokeWidth="0.75"
        fontFamily="Garuda"
        fontSize="30"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="normal"
        letterSpacing="0"
        wordSpacing="0"
        xmlSpace="preserve"
        style={{ lineHeight: '1.25', InkscapeFontSpecification: 'Garuda' }}
      ></text>
      <flowRoot
        fill="#000"
        fillOpacity="1"
        stroke="none"
        fontFamily="Bitstream Charter"
        fontSize="40"
        style={{ lineHeight: '1.25' }}
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="normal"
        letterSpacing="0"
        transform="matrix(.75 0 0 .75 317.46 31.947)"
        wordSpacing="0"
        xmlSpace="preserve"
      >
        <flowRegion>
          <path d="M-16.862 -8.816H370.37399999999997V83.63199999999999H-16.862z"></path>
        </flowRegion>
        <flowPara></flowPara>
      </flowRoot>
      <flowRoot
        fill="#000"
        fillOpacity="1"
        stroke="none"
        fontFamily="Bitstream Charter"
        fontSize="40"
        style={{ lineHeight: '1.25' }}
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="normal"
        letterSpacing="0"
        wordSpacing="0"
        xmlSpace="preserve"
      >
        <flowRegion>
          <path d="M-44.189 -39.443H340.722V111.14900000000002H-44.189z"></path>
        </flowRegion>
        <flowPara></flowPara>
      </flowRoot>
      <flowRoot
        fill="#000"
        fillOpacity="1"
        stroke="none"
        fontFamily="Bitstream Charter"
        fontSize="40"
        style={{ lineHeight: '1.25' }}
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="normal"
        letterSpacing="0"
        transform="matrix(.75 0 0 .75 317.46 31.947)"
        wordSpacing="0"
        xmlSpace="preserve"
      >
        <flowRegion>
          <path d="M-156.988 -116.774H425.03000000000003V197.78300000000002H-156.988z"></path>
        </flowRegion>
        <flowPara></flowPara>
      </flowRoot>
    </g>
  </svg>
);

export default SvgScholariLogoLogin;
