import { useDispatch, useSelector } from 'react-redux';
import { login } from './actions';
import { useForm } from 'react-hook-form';
import {
  InputGroup,
  InputText,
  Button,
  ButtonLink,
  InputError,
  FormError,
  Spacer,
  RouteLink,
} from 'modules/components';

export default function LoginPage() {
  const isError = useSelector((state) => state.login.error);
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  function onSubmit(values) {
    dispatch(login(values.email, values.password));
  }

  return (
    <section className="section-unauth">
      <h1>Login</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup error={errors.email}>
          <label htmlFor="email">E-Mail-Adresse</label>
          <InputText
            id="email"
            name="email"
            type="text"
            autoFocus
            ref={register({
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Bitte gib eine gültige E-Mail-Adresse ein',
              },
              required: 'Bitte gib eine E-Mail-Adresse ein.',
            })}
          />
          <InputError>{errors.email && errors.email.message}</InputError>
        </InputGroup>

        <InputGroup error={errors.password}>
          <label htmlFor="password">Passwort</label>
          <InputText
            id="password"
            name="password"
            type="password"
            ref={register({
              required: 'Bitte gib ein Passwort ein.',
            })}
          />
          <InputError>{errors.password && errors.password.message}</InputError>
        </InputGroup>

        {isError ? <FormError>Die E-Mail-Adresse oder das Passwort ist falsch.</FormError> : ''}

        <InputGroup>
          <Button type="submit">Login</Button>
          <RouteLink to="/reset">Passwort vergessen</RouteLink>
        </InputGroup>
      </form>

      <b>Noch nicht angemeldet?</b>
      <Spacer></Spacer>
      <div>
        {' '}
        <ButtonLink to="/register">Jetzt Scholari kostenlos registeren</ButtonLink>
      </div>
    </section>
  );
}
