import * as t from './actionTypes';
import { get } from 'utilities';

export function setFirstname(firstname) {
  return { type: t.SET_FIRSTNAME, firstname };
}

export function setGender(gender) {
  return { type: t.SET_GENDER, gender };
}

export function setYear(year) {
  return { type: t.SET_YEAR, year };
}

export function setCategory(id) {
  return { type: t.SET_CATEGORY, id };
}

export function setSection(id) {
  return { type: t.SET_SECTION, id };
}

export function setAssessment(assessment) {
  return { type: t.SET_ASSESSMENT, assessment };
}

export function setGrade(id) {
  return { type: t.SET_GRADE, id };
}

export function setText(text) {
  return { type: t.SET_TEXT, text };
}

export function abortAssessment() {
  return { type: t.ABORT };
}

export function fetchCurriculum() {
  return (dispatch) => {
    dispatch({ type: t.FETCH_GENERATOR_REQUESTED });

    get('/api/curriculum')
      .then((data) => {
        dispatch({ type: t.FETCH_GENERATOR_COMPLETE, data });
      })
      .catch((error) => {
        dispatch({ type: t.FETCH_GENERATOR_ERROR, error });
      });
  };
}
