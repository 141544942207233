import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from './actions';
import { addCategory, sortCategories, deleteCategory, updateCategory } from './actions';
import { push } from 'connected-react-router';
import {
  ButtonBar,
  Draggable,
  InputText,
  Spacer,
  InputGroup,
  Button,
  InputButtonGroup,
  InputButtonGroupOption,
  Icon,
  Loader,
} from 'modules/components';

export default function CategoryList() {
  const dispatch = useDispatch();

  const useFetching = () => {
    useEffect(() => {
      dispatch(fetchCategories());
    }, []);
  };

  dispatch(useFetching);

  const loading = useSelector((state) => state.category.requested);
  const years = useSelector((state) => state.generator.years);
  const [activeYear, setActiveYear] = useState(years.length ? years[0] : null);
  const [editable, setEditable] = useState(false);
  const categories = useSelector((state) =>
    state.category.categories.filter((category) => category.year === activeYear).sort((a, b) => a.sort - b.sort)
  );

  function createCategory() {
    dispatch(
      addCategory(
        'Neue Kategorie',
        activeYear,
        categories.reduce((carry, category) => Math.max(carry, category.sort), 0) + 1
      )
    );
  }

  function confirmDeleteCategory(id) {
    if (window.confirm('Sind Sie sich sicher?')) {
      // debugger;
      dispatch(deleteCategory(id));
    }
  }

  function updateCategoryOrder(categories) {
    const sort = categories.reduce((sort, category, index) => ({ ...sort, [category.id]: index }), {});
    dispatch(sortCategories(sort));
  }

  if (loading) {
    return <Loader />;
  } else {
    return (
      <section>
        <h1>Beurteilungen</h1>
        <p>Texte werden automatisch nach Eingabe gespeichert.</p>
        <Spacer />
        <h2>Schuljahr</h2>
        <form>
          <InputGroup>
            <InputButtonGroup>
              {years.map((year) => (
                <InputButtonGroupOption key={year} active={year === activeYear} onClick={() => setActiveYear(year)}>
                  {year}
                </InputButtonGroupOption>
              ))}
            </InputButtonGroup>
          </InputGroup>

          <Spacer />
        </form>
        <h2 className="flex">Kategorien</h2>
        <form>
          <Draggable
            id="categories"
            items={categories}
            onReorder={updateCategoryOrder}
            render={(category, provided) => (
              <div key={category.id} ref={provided.innerRef} {...provided.draggableProps} className="dnd-element">
                <Icon className="dnd-icon" {...provided.dragHandleProps}>
                  drag_handle
                </Icon>
                <InputText
                  className="dnd-text"
                  value={category.name}
                  onChange={(e) => dispatch(updateCategory(category.id, e.target.value))}
                />
                <Button onClick={() => dispatch(push(`/categories/${category.id}`))} small type="secondary">
                  Bereiche verwalten
                </Button>
                {editable && (
                  <button onClick={() => confirmDeleteCategory(category.id)} className="dnd-button">
                    Löschen
                  </button>
                )}
              </div>
            )}
          />
        </form>
        <ButtonBar>
          <Button onClick={createCategory}>Neue Kategorie hinzufügen</Button>
          <Button onClick={() => setEditable(!editable)}>Bearbeiten</Button>
        </ButtonBar>
      </section>
    );
  }
}
