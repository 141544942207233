import { post } from 'utilities';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import login from '../application/login';
import { InputGroup, InputText, Button, InputError, TermsAndConditions } from 'modules/components';

// Sobald ein Lehrer einer Schule ein Passwort gewählt hat wird er automatisch eingelogged nach
// dem Klick auf den Button "Schule Beitreten"
function registerTeacher(password, invitation_id) {
  return (dispatch) => {
    post('/api/teachers/register', { password, invitation_id })
      .then((data) => {
        dispatch(login.actions.login(data.user.email, password));
      })
      .catch((error) => console.log(error));
  };
}

export default function TeacherRegisterPage({ match }) {
  const dispatch = useDispatch();
  const invitationId = match.params.id;
  const { handleSubmit, register, errors } = useForm();

  function onSubmit(values) {
    dispatch(registerTeacher(values.password, invitationId));
  }

  return (
    <section className="section-unauth">
      <h1>Schule beitreten</h1>
      <p>Tritt deiner Schule bei, wähle hierfür ein Passwort</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup error={errors.password}>
          <label htmlFor="password">Passwort</label>
          <InputText
            id="password"
            name="password"
            type="password"
            ref={register({
              required: 'Bitte gib ein Passwort ein.',
            })}
          />
          <InputError>{errors.password && errors.password.message}</InputError>
        </InputGroup>

        <TermsAndConditions>
          Mit der Anmeldung akzeptierst du unsere{' '}
          <a href="https://scholari.de/datenschutz/">Datenschutz&shy;erklärung</a> und unsere
          <a href="https://scholari.de/agb"> AGB</a>.
        </TermsAndConditions>

        <Button type="submit">Schule beitreten</Button>
      </form>
    </section>
  );
}
