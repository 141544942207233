import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchSections, addSection, updateSection, deleteSection, sortSections } from './actions';
import { Draggable, InputText, ButtonBar, Button, Icon, Loader } from 'modules/components';

export default function SectionList({ match }) {
  const categoryId = match.params.id;
  const dispatch = useDispatch();
  const useFetching = () => {
    useEffect(() => {
      dispatch(fetchSections(categoryId));
    }, []);
  };
  dispatch(useFetching);

  const isLoading = useSelector((state) => state.section.requested);
  const category = useSelector((state) => state.section.category);
  const sections = useSelector((state) => state.section.sections.sort((a, b) => a.sort - b.sort));
  const [editable, setEditable] = useState(false);

  function createSection() {
    dispatch(addSection(categoryId, '', sections.reduce((carry, section) => Math.max(carry, section.sort), 0) + 1));
  }

  function confirmDeleteSection(id) {
    if (window.confirm('Sind Sie sich sicher?')) {
      dispatch(deleteSection(id));
    }
  }

  function updateSectionsOrder(sections) {
    const sort = sections.reduce((sort, section, index) => ({ ...sort, [section.id]: index }), {});
    dispatch(sortSections(sort));
  }

  function cancelOnClick() {
    window.history.back();
  }

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <section>
        <h1>
          {category.year} {'>'} {category.name}
        </h1>
        <h2>Bereiche</h2>
        <form>
          <Draggable
            id="sections"
            items={sections}
            onReorder={updateSectionsOrder}
            render={(section, provided) => (
              <div key={section.id} ref={provided.innerRef} {...provided.draggableProps} className="dnd-element">
                <Icon className="dnd-icon" {...provided.dragHandleProps}>
                  drag_handle
                </Icon>
                <InputText
                  className="dnd-text"
                  value={section.name}
                  onChange={(e) => dispatch(updateSection(section.id, e.target.value))}
                />
                <Button onClick={() => dispatch(push(`/sections/${section.id}`))} small type="secondary">
                  Beurteilungen verwalten
                </Button>
                {editable && (
                  <button onClick={() => confirmDeleteSection(section.id)} className="dnd-button">
                    Löschen
                  </button>
                )}
              </div>
            )}
          />
        </form>
        <ButtonBar>
          <Button onClick={cancelOnClick} type="secondary">
            Zurück
          </Button>
          <Button onClick={createSection}>Neuer Bereich</Button>
          <Button onClick={() => setEditable(!editable)}>Bearbeiten</Button>
        </ButtonBar>
      </section>
    );
  }
}
