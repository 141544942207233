import * as t from './actionTypes';
import { get, post, put, del } from 'utilities';

export function fetchCategories() {
  return (dispatch) => {
    dispatch({ type: t.FETCH_CATEGORIES_REQUESTED });

    get('/api/categories')
      .then((data) => {
        dispatch({ type: t.FETCH_CATEGORIES_COMPLETE, data });
      })
      .catch((error) => {
        dispatch({ type: t.FETCH_CATEGORIES_ERROR, error });
      });
  };
}

export function addCategory(name, year, sort) {
  return (dispatch) => {
    post('/api/categories', { name, year, sort })
      .then((category) => {
        dispatch({ type: t.ADD_CATEGORY, category });
      })
      .catch((error) => console.log(error));
  };
}

export function updateCategory(id, name) {
  put('/api/categories/' + id, { name }).catch((error) => console.log(error));
  return { type: t.UPDATE_CATEGORY, id, name };
}

export function deleteCategory(id) {
  del('/api/categories/' + id);
  return { type: t.DELETE_CATEGORY, id };
}

export function sortCategories(sort) {
  post('/api/categories/sort', sort).catch((error) => console.log(error));
  return { type: t.SORT_CATEGORIES, sort };
}
