import * as t from './actionTypes';
import { get, put } from 'utilities';
import user from '../user';

export function fetchAccount() {
  return (dispatch) => {
    dispatch({ type: t.FETCH_ACCOUNT_REQUESTED });
    get('/api/users/account')
      .then((data) => {
        dispatch({ type: t.FETCH_ACCOUNT_COMPLETE, data });
      })
      .catch((error) => {
        dispatch({ type: t.FETCH_ACCOUNT_ERROR, error });
      });
  };
}

export function updateAccount(
  firstname,
  lastname,
  email,
  account_status,
  country,
  school,
  street,
  zip,
  city,
  account_holder,
  payment_type,
  iban,
  bic
) {
  return (dispatch) => {
    dispatch({ type: t.UPDATE_ACCOUNT_REQUESTED });
    put('/api/users/account', {
      firstname,
      lastname,
      email,
      account_status,
      country,
      school,
      street,
      zip,
      city,
      account_holder,
      payment_type,
      iban,
      bic,
    })
      .then((data) => {
        dispatch({ type: t.UPDATE_ACCOUNT_COMPLETE, data });
        dispatch(user.actions.fetchUser());
      })
      .catch((error) => {
        dispatch({ type: t.UPDATE_ACCOUNT_ERROR, error });
      });
  };
}
