import { del, useGet } from 'utilities';
import { Table, TableRow, TableCell, TableHead, Loader } from '../components';

export default function TeachersList() {
  const { data, isRequesting, hasError, errorMessage, refetch } = useGet('/api/teachers');

  const confirmDeleteTeacher = (id) => {
    if (window.confirm('Sind Sie sich sicher?')) {
      del('/api/teachers/' + id);
      refetch();
    }
  };

  if (data) {
    return (
      <section>
        <h1>Lehrkräfte</h1>
        <Table>
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell>E-Mail</TableCell>
            <TableCell>Bearbeiten</TableCell>
          </TableHead>
          {data.teachers.map((teacher) => (
            <TableRow key="teacher.id">
              <TableCell>
                {teacher.firstname} {teacher.lastname}
              </TableCell>
              <TableCell>{teacher.email}</TableCell>
              <TableCell>
                {' '}
                <button onClick={() => confirmDeleteTeacher(teacher.id)} className="dnd-button">
                  Löschen
                </button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </section>
    );
  }

  if (isRequesting) {
    return <Loader></Loader>;
  }

  if (hasError) {
    return <div>{errorMessage}</div>;
  }

  return <></>;
}
