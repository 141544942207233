import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './css/index.css';
import './css/tailwind.css';
import store, { history } from './store';
import { ConnectedRouter } from 'connected-react-router';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);