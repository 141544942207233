import * as t from './actionTypes';
import { get, post, put, del } from 'utilities';

export function fetchSections(categoryId) {
  return (dispatch) => {
    dispatch({ type: t.FETCH_SECTIONS_REQUESTED });

    get('/api/categories/' + categoryId + '/sections')
      .then((data) => {
        dispatch({ type: t.FETCH_SECTIONS_COMPLETE, data });
      })
      .catch((error) => {
        dispatch({ type: t.FETCH_SECTIONS_ERROR, error });
      });
  };
}

export function addSection(category_id, name, sort) {
  return (dispatch) => {
    post('/api/sections', { category_id, name, sort })
      .then((section) => {
        dispatch({ type: t.ADD_SECTION, section });
      })
      .catch((error) => console.log(error));
  };
}

export function updateSection(id, name) {
  put('/api/sections/' + id, { name }).catch((error) => console.log(error));
  return { type: t.UPDATE_SECTION, id, name };
}

export function sortSections(sort) {
  post('/api/sections/sort', sort).catch((error) => console.log(error));
  return { type: t.SORT_SECTIONS, sort };
}

export function deleteSection(id) {
  del('/api/sections/' + id).catch((error) => console.log(error));
  return { type: t.DELETE_SECTION, id };
}
