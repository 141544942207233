import * as t from './actionTypes';
import { get } from 'utilities';

export function fetchUser() {
  return (dispatch) => {
    dispatch({ type: t.FETCH_USER_REQUESTED });

    get('/api/users')
      .then((data) => {
        dispatch({ type: t.FETCH_USER_COMPLETE, data });

        // Mache nur weiter wenn der Account-Status test ist.
        if ('test' === data.user.account_status) {
          const duration = calcTestDuration(data.user.trial_expires);
          dispatch({ type: t.SET_TESTDURATION, duration });
        }
      })
      .catch((error) => {
        dispatch({ type: t.FETCH_USER_ERROR, error });
      });
  };
}

function calcTestDuration(date) {
  const expiresDate = new Date(date);
  const today = new Date();
  const durationInMs = expiresDate - today;
  const duration = Math.round(durationInMs / 86400000);
  return duration;
}
