import { useSelector } from 'react-redux';
import GeneratorNavigation from './GeneratorNavigation';
import GeneratorText from './GeneratorText';
import { Highlight } from '../components';

export default function GeneratorTool() {
  const year = useSelector((state) => state.generator.year);
  const firstname = useSelector((state) => state.generator.firstname);

  return (
    <section className="generator">
      <h1>
        Neues Zeugnis: <Highlight>{firstname}</Highlight> (Schuljahr: {year})
      </h1>
      <div className="generator-layout">
        <GeneratorNavigation />
        <GeneratorText />
      </div>
    </section>
  );
}
