import { useDispatch, useSelector } from 'react-redux';
import { reset } from './actions';
import { useForm } from 'react-hook-form';

import { InputGroup, InputText, Button, InputError, FormError, TermsAndConditions } from 'modules/components';

export default function ResetPage() {
  const dispatch = useDispatch();
  const isError = useSelector((state) => state.reset.error);
  const { handleSubmit, register, errors } = useForm();

  function onSubmit(values) {
    dispatch(reset(values.email));
  }

  return (
    <section className="section-unauth">
      <h1>Passwort zurücksetzen</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup error={errors.email}>
          <label for="email">E-Mail-Adresse</label>
          <InputText
            id="email"
            name="email"
            type="text"
            autoFocus
            ref={register({
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Bitte gib eine gültige E-Mail-Adresse ein',
              },
              required: 'Bitte gib eine E-Mail-Adresse ein.',
            })}
          />
          <InputError>{errors.email && errors.email.message}</InputError>
        </InputGroup>

        {isError ? <FormError>Diese E-Mail-Adresse existiert nicht.</FormError> : ''}

        <InputGroup>
          <Button type="submit">Passwort zurücksetzen</Button>
          <TermsAndConditions>Nach dem Klick bitte dein Postfach prüfen</TermsAndConditions>
        </InputGroup>
      </form>
    </section>
  );
}
