import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function RegistrationBar() {
  const testDuration = useSelector((state) => state.user.duration);
  const testDurationText = setDurationText(testDuration);

  // Fällt der Testzeitraum unter einem Tag wird statt
  // "Tagen", "einem Tag" ausgegeben
  function setDurationText(testDuration) {
    if (testDuration > 1) {
      return <b> {testDuration} Tagen. </b>;
    }
    if (testDuration === 1) {
      return <b> einem Tag. </b>;
    }
  }

  return (
    <aside className="registration-bar">
      <div>
        Dein Testzeitraum endet in
        {testDurationText}
        Wenn du die Vorteile des Generators in diesem Account weiter nutzen möchtest, klicke
        <b>
          <Link to={'/account'}> hier!</Link>
        </b>
      </div>
    </aside>
  );
}
