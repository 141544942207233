import { TermsAndConditions, Icon } from './Components';

export default function PriceBox(props) {
  const accountType = props.accountType;
  const currentAccountDetails = (accountType) => {
    switch (accountType) {
      case 'Referendar':
        return {
          type: 'Referendar',
          price: '15,99€',
          features: ['Generator', 'Beurteilungsverwaltung'],
          conditions:
            'Der Preis gilt für zwei Jahre. Danach wird auf den Account-Typ Lehrer gewechselt für 24,99 €/Jahr. Der Preise ist inklusive festgelegter Mehrwertsteuer, abhängig vom Land',
        };

      case 'Lehrer':
        return {
          type: 'Lehrer',
          price: '24,99€',
          features: ['Generator', 'Beurteilungsverwaltung'],
          conditions: 'Der Preis ist inklusive festgelegter Mehrwertsteuer, abhängig vom Land',
        };

      case 'Schule':
        return {
          type: 'Schule',
          price: '129,99€',
          features: ['Schulgenerator', 'Beurteilungsverwaltung', 'Lehrerverwaltung'],
          conditions: 'Bis 35 Lehrer-Accounts. Der Preis ist inklusive festgelegter Mehrwertsteuer, abhängig vom Land',
        };

      case 'Große Schule':
        return {
          type: 'Große Schule',
          price: '169,99€',
          features: ['Schulgenerator', 'Beurteilungsverwaltung', 'Lehrerverwaltung'],
          conditions: 'Ab 35 Lehrer-Accounts. Der Preis ist inklusive festgelegter Mehrwertsteuer, abhängig vom Land',
        };

      default:
        return {};
    }
  };
  const accountDetails = currentAccountDetails(accountType);

  return (
    <aside className="price-box">
      <div className="price-box-header">
        <div>{accountDetails.type}</div>
      </div>
      <div className="price-box-body">
        <div className="price-box-price">
          <div className="price-box-price-value">{accountDetails.price}</div>
          <div className="price-box-price-period">jährlich</div>
        </div>

        <div className="price-box-features">
          <ul>
            {accountDetails.features.map((feature) => (
              <li>
                <Icon className="price-box-feature-icon">check</Icon>
                <span className="price-box-feature-description">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        <TermsAndConditions>{accountDetails.conditions}</TermsAndConditions>
      </div>
    </aside>
  );
}
