import * as t from './actionTypes';
import { login } from '../login/actions';
import { post } from 'utilities';

export function registration(email, password, account_type) {
  return (dispatch) => {
    dispatch({ type: t.REGISTER_USER_REQUESTED });
    post('/api/users', { email, password, account_type })
      .then((data) => {
        dispatch({ type: t.REGISTER_USER_COMPLETE, data });
        dispatch(login(email, password));
      })
      .catch((error) => {
        dispatch({ type: t.REGISTER_USER_ERROR, error });
      });
  };
}
