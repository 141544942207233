import * as t from './actionTypes';
import user from '../user';
import account from '../account';
import { push } from 'connected-react-router';
import { get, post } from 'utilities';

export function login(email, password) {
  return (dispatch) => {
    dispatch({ type: t.LOGIN_USER_REQUESTED });

    post('/api/login', { email, password })
      .then(() => {
        dispatch({ type: t.LOGIN_USER_COMPLETE });
        dispatch(user.actions.fetchUser());
        dispatch(account.actions.fetchAccount());
        dispatch(push('/student'));
      })
      .catch((error) => {
        dispatch({ type: t.LOGIN_USER_ERROR, error });
      });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: t.LOGGED_USER_OUT });
    get('/api/logout').catch(() => {});
  };
}
