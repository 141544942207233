import { post } from 'utilities';
import { useForm } from 'react-hook-form';
import { InputGroup, InputText, Button, InputError } from '../components';

export default function TeachersInviteForm() {
  const { handleSubmit, register, errors, reset } = useForm();

  const onSubmit = (values) => {
    const email = values.email;
    post('/api/teachers/invite', { email });
    reset();
  };

  return (
    <section>
      <h1>Lehrkraft einladen</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup error={errors.email}>
          <label htmlFor="email">E-Mail-Adresse</label>
          <InputText
            autoFocus
            id="email"
            name="email"
            type="text"
            ref={register({
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Bitte gib eine gültige E-Mail-Adresse ein',
              },
              required: 'Bitte gib eine E-Mail-Adresse ein.',
            })}
          />
          <InputError>{errors.email && errors.email.message}</InputError>
        </InputGroup>

        <Button type="submit">Lehrkraft einladen</Button>
      </form>
    </section>
  );
}
