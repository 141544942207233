export const SET_FIRSTNAME = 'SET_FIRSTNAME';
export const SET_GENDER = 'SET_GENDER';
export const SET_YEAR = 'SET_YEAR';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_SECTION = 'SET_SECTION';
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export const SET_GRADE = 'SET_GRADE';
export const SET_TEXT = 'SET_TEXT';
export const ABORT = 'ABORT';

export const FETCH_GENERATOR_REQUESTED = 'FETCH_GENERATOR_REQUESTED';
export const FETCH_GENERATOR_COMPLETE = 'FETCH_GENERATOR_COMPLETE';
export const FETCH_GENERATOR_ERROR = 'FETCH_GENERATOR_ERROR';
