import * as t from './actionTypes';

const initialValue = {
  requested: false,
  error: null,
  category: {},
  sections: []
};

export default function sectionReducer(state = initialValue, action) {
  switch (action.type) {
    case t.ADD_SECTION:
      return { ...state, sections: [...state.sections, action.section] };
    case t.UPDATE_SECTION:
      return {
        ...state,
        sections: state.sections.map(section =>
          section.id === action.id ? { ...section, name: action.name } : section
        )
      };
    case t.DELETE_SECTION:
      return {
        ...state,
        sections: state.sections.filter(section => section.id !== action.id)
      };
    case t.SORT_SECTIONS:
      return {
        ...state,
        sections: state.sections.map(section => ({
          ...section,
          sort: action.sort[section.id]
        }))
      };
    case t.FETCH_SECTIONS_REQUESTED:
      return {
        ...state,
        requested: true
      };
    case t.FETCH_SECTIONS_COMPLETE:
      return {
        ...state,
        requested: false,
        category: action.data.category,
        sections: action.data.sections
      };
    case t.FETCH_SECTIONS_ERROR:
      return {
        ...state,
        requested: false,
        error: action.error.message
      };
    default:
      return state;
  }
}
