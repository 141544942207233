import * as t from './actionTypes';

const initialValue = {
  requested: false,
  error: null,
  country: 'Deutschland',
  school: '',
  street: '',
  zip: '',
  city: '',
  accountHolder: '',
  paymentType: 'Rechnung',
  iban: '',
  bic: '',
};

export default function accountReducer(state = initialValue, action) {
  switch (action.type) {
    case t.FETCH_ACCOUNT_REQUESTED:
      return {
        ...state,
        requested: true,
      };
    case t.FETCH_ACCOUNT_COMPLETE:
      return {
        ...state,
        requested: false,
        country: action.data.user_detail.country,
        school: action.data.user_detail.school,
        state: action.data.user_detail.state,
        street: action.data.user_detail.street,
        zip: action.data.user_detail.zip,
        city: action.data.user_detail.city,
        type: action.data.user_payment.type,
        accountHolder: action.data.user_payment.account_holder,
        paymentType: action.data.user_payment.payment_type,
        iban: action.data.user_payment.iban,
        bic: action.data.user_payment.bic,
      };
    case t.FETCH_ACCOUNT_ERROR:
      return {
        ...state,
        requested: true,
        error: action.error.message,
      };
    case t.UPDATE_ACCOUNT_REQUESTED:
      return {
        ...state,
        requested: true,
      };
    case t.UPDATE_ACCOUNT_COMPLETE:
      return {
        ...state,
        requested: false,
        country: action.data.user_detail.country,
        school: action.data.user_detail.school,
        state: action.data.user_detail.state,
        street: action.data.user_detail.street,
        zip: action.data.user_detail.zip,
        city: action.data.user_detail.city,
        type: action.data.user_payment.type,
        accountHolder: action.data.user_payment.account_holder,
        paymentType: action.data.user_payment.payment_type,
        iban: action.data.user_payment.iban,
        bic: action.data.user_payment.bic,
      };
    case t.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        requested: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
