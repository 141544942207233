import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { setText, abortAssessment } from './actions';
import { InputTextarea, Button, ButtonBar } from '../components';

export default function GeneratorText() {
  const dispatch = useDispatch();
  const assessment = useSelector((state) => state.generator.assessment);
  const text = useSelector((state) => state.generator.text);
  const textElement = useRef(null);

  useEffect(() => {
    insertAssessmentToText(assessment);
  }, [assessment]);

  function insertAssessmentToText(newAssessment) {
    // Der Reducer-Default-Wert für den Text ist ''.
    // Daher wird ohne die folgende If-Abfrage am Anfang ein Leerzeichen + ' '
    if (newAssessment !== '') {
      const startPos = textElement.current.selectionStart;
      const endPos = textElement.current.selectionEnd;

      // Hier wird der neue Text gebaut
      dispatch(setText(text.substring(0, startPos) + newAssessment + ' ' + text.substring(endPos, text.length)));

      // Hier wird der Courser gesetzt
      textElement.current.selectionStart = startPos + newAssessment.length;
      textElement.current.selectionEnd = endPos + newAssessment.length;
    }
    textElement.current.focus();
  }

  function handleAbort() {
    dispatch(abortAssessment());
    dispatch(push('/student'));
  }

  function copyToClipboard() {
    textElement.current.focus();
    textElement.current.select();
    document.execCommand('copy');

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }
  return (
    <div className="generator-text">
      <h2>Zeugnistext</h2>
      <InputTextarea
        ref={textElement}
        className="generator-textarea"
        value={text}
        onChange={(e) => dispatch(setText(e.target.value))}
      />
      <ButtonBar>
        <Button onClick={copyToClipboard}>Zeugnis kopieren</Button>
        <Button type="secondary" onClick={handleAbort}>
          Neues Zeugnis erstellen
        </Button>
      </ButtonBar>
    </div>
  );
}
