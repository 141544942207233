import * as t from './actionTypes';

const initialValue = {
  requested: false,
  error: null,
  years: ['1.1', '1.2', '2.1', '2.2', '3.1', '3.2', '4.1', '4.2', 'allgemein'],
  year: '1.1',
  genders: ['männlich', 'weiblich', 'neutral'],
  gender: 'männlich',
  grades: ['sehr gut', 'gut', 'befriedigend', 'ausreichend', 'mangelhaft', 'ungenügend', 'allgemein'],
  grade: null,
  categories: [],
  category: null,
  sections: [],
  section: '',
  assessments: [],
  assessment: '',
  firstname: '',
  text: '',
};

export default function generatorReducer(state = initialValue, action) {
  switch (action.type) {
    case t.SET_FIRSTNAME:
      return { ...state, firstname: action.firstname };
    case t.SET_GENDER:
      return { ...state, gender: action.gender };
    case t.SET_YEAR:
      return { ...state, year: action.year };
    case t.SET_CATEGORY:
      return {
        ...state,
        category: action.id,
        section: state.sections.filter((section) => section.category_id === action.id)[0].id,
      };
    case t.SET_SECTION:
      return { ...state, section: action.id };
    case t.SET_ASSESSMENT:
      return { ...state, assessment: action.assessment };
    case t.SET_GRADE:
      return { ...state, grade: action.id };
    case t.SET_TEXT:
      return { ...state, text: action.text };
    case t.ABORT:
      return {
        ...state,
        assessment: '',
        firstname: '',
        text: '',
        gender: state.genders[0],
        category: state.categories[0].id,
        sectionId: state.sections[0].id,
        grade: state.grades[0],
      };
    case t.FETCH_GENERATOR_REQUESTED:
      return {
        ...state,
        requested: true,
      };
    case t.FETCH_GENERATOR_COMPLETE:
      return {
        ...state,
        requested: false,
        categories: action.data.categories,
        sections: action.data.sections,
        assessments: action.data.assessments,
      };
    case t.FETCH_GENERATOR_ERROR:
      return {
        ...state,
        requested: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
