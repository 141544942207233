import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import assessment from './modules/curriculum/assessments/reducer';
import category from './modules/curriculum/categories/reducer';
import generator from './modules/generator/reducer';
import login from './modules/application/login/reducer';
import account from './modules/application/account/reducer';
import reset from './modules/application/reset/reducer';
import register from './modules/application/register/reducer';
import section from './modules/curriculum/sections/reducer';
import user from './modules/application/user/reducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  assessment,
  account,
  category,
  generator,
  login,
  register,
  section,
  reset,
  user,
  router: connectRouter(history),
});

const store = createStore(rootReducer, compose(applyMiddleware(routerMiddleware(history), thunk, logger)));

export default store;
