import { Spinner } from './Components';

export default function Loader() {
  return (
    <section>
      <Spinner width="50px" height="50px" viewBox="0 0 66 66">
        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </Spinner>
    </section>
  );
}
