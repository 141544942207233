import { useState, useEffect } from 'react';

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
}

export function useFormInput(initialValue = '') {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
}

export function usePost(initialUrl = '', initialParams = {}) {
  const [url, updateUrl] = useState(initialUrl);
  const [params, updateParams] = useState(initialParams);
  const [data, setData] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const queryString = Object.keys(params)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');

  const fetchData = async () => {
    setIsRequesting(true);
    try {
      const response = await fetch(`${url}${queryString}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (response.ok) {
        setData(result);
      } else {
        setHasError(true);
        setErrorMessage(result);
      }
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.message);
    } finally {
      setIsRequesting(false);
    }
  };
  fetchData();
  return { data, isRequesting, hasError, errorMessage, updateUrl, updateParams };
}

export function useGet(initialUrl = '', initialParams = {}, skip = false) {
  const [url, updateUrl] = useState(initialUrl);
  const [params, updateParams] = useState(initialParams);
  const [data, setData] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [refetchIndex, setRefetchIndex] = useState(0);
  const queryString = Object.keys(params)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');

  const refetch = () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);

  useEffect(() => {
    const fetchData = async () => {
      if (skip) return;
      setIsRequesting(true);
      try {
        const response = await fetch(`${url}${queryString}`);
        const result = await response.json();
        if (response.ok) {
          setData(result);
        } else {
          setHasError(true);
          setErrorMessage(result);
        }
      } catch (err) {
        setHasError(true);
        setErrorMessage(err.message);
      } finally {
        setIsRequesting(false);
      }
    };
    fetchData();
  }, [url, params, refetchIndex]);
  return { data, isRequesting, hasError, errorMessage, updateUrl, updateParams, refetch };
}
