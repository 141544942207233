import * as t from './actionTypes';

const initialValue = {
  requested: false,
  error: null,
  authorized: false
};

export default function loginReducer(state = initialValue, action) {
  switch (action.type) {
    case t.LOGIN_USER_REQUESTED:
      return { ...state, requested: true, error: false, authorized: false };
    case t.LOGIN_USER_ERROR:
      return { ...state, requested: false, error: action.error.message, authorized: false };
    case t.LOGIN_USER_COMPLETE:
      return { ...state, requested: false, error: false, authorized: true };
    case t.LOGGED_USER_OUT:
      return { ...state, requested: false, authorized: false };
    default:
      return state;
  }
}
