import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { useFormInput } from 'utilities';
import { setFirstname, setGender, setYear, fetchCurriculum } from './actions';
import {
  InputGroup,
  InputText,
  Button,
  InputButtonGroup,
  InputButtonGroupOption,
  Spacer,
  InputError,
  Loader,
  ButtonBar,
} from '../components';

export default function StudentForm() {
  const dispatch = useDispatch();

  const useFetching = () => {
    useEffect(() => {
      dispatch(fetchCurriculum());
    }, []);
  };

  dispatch(useFetching);

  const isLoading = useSelector((state) => state.generator.requested);
  const genders = useSelector((state) => state.generator.genders.filter((gender) => gender !== 'neutral'));
  const activeGender = useSelector((state) => state.generator.gender);
  const years = useSelector((state) => state.generator.years.filter((year) => year !== 'allgemein'));
  const activeYear = useSelector((state) => state.generator.year);
  const { handleSubmit, register, errors } = useForm();
  const firstname = useFormInput('');

  function onSubmit() {
    dispatch(setFirstname(firstname.value));
    dispatch(push('/generator'));
  }

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <section>
        <h1>Zeugnisgenerator</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Angaben zum Schüler</h2>

          <InputGroup>
            <label>Schuljahr</label>
            <InputButtonGroup>
              {years.map((year) => (
                <InputButtonGroupOption key={year} active={year === activeYear} onClick={() => dispatch(setYear(year))}>
                  {year}
                </InputButtonGroupOption>
              ))}
            </InputButtonGroup>
          </InputGroup>

          <InputGroup error={errors.firstname}>
            <label>Vorname</label>
            <InputText
              name="firstname"
              ref={register({
                required: 'Bitte gib einen Vornamen ein',
              })}
              {...firstname}
            />
            <InputError>{errors.firstname && errors.firstname.message}</InputError>
          </InputGroup>

          <InputGroup>
            <label>Geschlecht</label>
            <InputButtonGroup>
              {genders.map((gender) => (
                <InputButtonGroupOption
                  key={gender}
                  active={gender === activeGender}
                  onClick={() => dispatch(setGender(gender))}
                >
                  {gender}
                </InputButtonGroupOption>
              ))}
            </InputButtonGroup>
          </InputGroup>

          <Spacer />
          <ButtonBar>
            <Button type="submit">Weiter zur Zeugniserstellung</Button>
          </ButtonBar>
        </form>
      </section>
    );
  }
}
