import * as t from './actionTypes';

const initialValue = {
  requested: false,
  error: null,
  section: [],
  assessments: [],
};

export default function assessmentReducer(state = initialValue, action) {
  switch (action.type) {
    case t.ADD_ASSESSMENT:
      return { ...state, assessments: [...state.assessments, action.assessment] };
    case t.UPDATE_ASSESSMENT:
      return {
        ...state,
        assessments: state.assessments.map((assessment) =>
          assessment.id === action.id
            ? {
                ...assessment,
                gender: action.gender,
                text: action.text,
                grade: action.grade,
              }
            : assessment
        ),
      };
    case t.DELETE_ASSESSMENT:
      return {
        ...state,
        assessments: state.assessments.filter((assessment) => assessment.id !== action.id),
      };
    case t.SORT_ASSESSMENTS:
      return {
        ...state,
        assessments: state.assessments.map((assessment) =>
          assessment.id in action.sort
            ? {
                ...assessment,
                sort: action.sort[assessment.id],
              }
            : assessment
        ),
      };
    case t.FETCH_ASSESSMENTS_REQUESTED:
      return {
        ...state,
        requested: true,
      };
    case t.FETCH_ASSESSMENTS_COMPLETE:
      return {
        ...state,
        requested: false,
        categories: action.data.categories,
        section: action.data.section,
        assessments: action.data.assessments,
      };
    case t.FETCH_ASSESSMENTS_ERROR:
      return {
        ...state,
        requested: false,
        error: action.error.message,
      };
    default:
      return state;
  }
}
