import * as t from './actionTypes';
import { get, post, put, del } from 'utilities';

export function fetchAssessments(sectionId) {
  return (dispatch) => {
    dispatch({ type: t.FETCH_ASSESSMENTS_REQUESTED });

    get('/api/sections/' + sectionId + '/assessments')
      .then((data) => {
        dispatch({ type: t.FETCH_ASSESSMENTS_COMPLETE, data });
      })
      .catch((error) => {
        dispatch({ type: t.FETCH_ASSESSMENTS_ERROR, error });
      });
  };
}

export function addAssessment(section_id, gender, text, grade, sort) {
  return (dispatch) => {
    post('/api/assessments', { section_id, gender, text, grade, sort })
      .then((assessment) => {
        dispatch({ type: t.ADD_ASSESSMENT, assessment });
      })
      .catch((error) => console.log(error));
  };
}

export function updateAssessment(id, gender, text, grade) {
  put('/api/assessments/' + id, { gender, text, grade }).catch((error) => console.log(error));
  return { type: t.UPDATE_ASSESSMENT, id, gender, text, grade };
}

export function deleteAssessment(id) {
  del('/api/assessments/' + id).catch((error) => console.log(error));
  return { type: t.DELETE_ASSESSMENT, id };
}

export function sortAssessments(sort) {
  post('/api/assessments/sort', sort).catch((error) => console.log(error));
  return { type: t.SORT_ASSESSMENTS, sort };
}
