import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoginPage, RegisterPage, ResetPage, AccountForm, ConfirmPage } from './modules/application';
import { AssessmentList, SectionList, CategoryList } from './modules/curriculum';
import { TeachersList, TeachersInviteForm, TeacherRegisterPage } from './modules/teachers';
import { GeneratorTool, StudentForm } from './modules/generator';
import { PrivateRoute, Page, Navigation, RegistrationBar, LinkBar } from './modules/components';
import SvgScholariLogoLogin from 'assets/SvgScholariLogoLogin';

export default function App() {
  const accountStatus = useSelector((state) => state.user.accountStatus);
  const isAuthorized = useSelector((state) => state.login.authorized);

  if (isAuthorized) {
    return (
      <Page>
        <Navigation />
        <main>
          {'test' === accountStatus ? <RegistrationBar /> : ''}
          <Switch>
            <Route path="/" exact>
              <Redirect to="/student" />
            </Route>
            <PrivateRoute path="/categories" component={CategoryList} exact />
            <PrivateRoute path="/categories/:id" component={SectionList} exact />
            <PrivateRoute path="/sections/:id" component={AssessmentList} exact />
            <PrivateRoute path="/generator" component={GeneratorTool} exact />
            <PrivateRoute path="/student" component={StudentForm} exact />
            <PrivateRoute path="/teachers" component={TeachersList} exact />
            <PrivateRoute path="/teachers/invite" component={TeachersInviteForm} exact />
            <PrivateRoute path="/account" component={AccountForm} exact />
          </Switch>
        </main>
      </Page>
    );
  } else {
    return (
      <Page className="page-unauth">
        <main className="main-unauth">
          <div className="unauth-logo">
            <SvgScholariLogoLogin/>
          </div>
          <Switch>
            <Route path="/login" component={LoginPage} exact />
            <Route path="/reset" component={ResetPage} exact />
            <Route path="/confirm/:token" component={ConfirmPage} exact />
            <Route path="/register/:token?" component={RegisterPage} exact />
            <Route path="/teacher/register/:id" component={TeacherRegisterPage} exact />
            <Route path="*" exact>
              <Redirect to="/login" />
            </Route>
          </Switch>
          <LinkBar />
        </main>
      </Page>
    );
  }
}
