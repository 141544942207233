import * as t from './actionTypes';

const initialValue = {
  requested: false,
  error: null,
  firstname: '',
  lastname: '',
  email: '',
  trialExpires: '',
  duration: '',
  accountType: '',
  accountStatus: ''
};

export default function userReducer(state = initialValue, action) {
  switch (action.type) {
    case t.FETCH_USER_REQUESTED:
      return {
        ...state,
        requested: true
      };
    case t.FETCH_USER_COMPLETE:
      return {
        ...state,
        requested: false,
        error: false,
        firstname: action.data.user.firstname,
        lastname: action.data.user.lastname,
        email: action.data.user.email,
        trialExpires: action.data.user.trial_expires,
        accountType: action.data.user.account_type,
        accountStatus: action.data.user.account_status
      };
    case t.FETCH_USER_ERROR:
      return {
        ...state,
        requested: false,
        error: action.error.message
      };
    case t.SET_TESTDURATION:
      return {
        ...state,
        duration: action.duration
      };
    default:
      return state;
  }
}
