import styled from 'styled-components';
import { NavLink, Link as RouterDomLink } from 'react-router-dom';

// Navigation
export const SideNavLink = styled(NavLink).attrs({
  activeClassName: `navigation-link-active`,
  className: `navigation-link`,
})``;

export const NavIcon = styled.i.attrs({
  className: `material-icons navigation-icon`,
})``;

// Layout-Styles
export const Page = styled.div.attrs({
  className: `page`,
})``;

export const Row = styled.div.attrs({
  className: `row`,
})``;

// Input-Styles

export const InputGroup = styled.div.attrs(({ error }) => ({
  className: `input-group ${error ? 'input-group-error' : ''}`,
}))``;

export const InputText = styled.input.attrs({
  className: `input-text`,
})``;

export const InputTextarea = styled.textarea.attrs({
  className: `input-textarea`,
})``;

export const InputRadio = styled.input.attrs({
  className: `input-radio`,
})``;

export const InputButtonGroup = styled.div.attrs({
  className: `input-button-group`,
})``;

export const InputButtonGroupOption = styled.div.attrs(({ active }) => ({
  className: `input-button-group-option
  ${active ? ' input-button-group-option-active' : ''}`,
}))``;

// Das Select auf der Beurteitungsseite
export const InputSelect = styled.select.attrs({
  className: `select`,
})``;

// Für die Wiedergabe von Client/Validierungsfehlern
export const InputError = styled.p.attrs({
  className: `input-text-error`,
})``;

// Für die Wiedergabe von Server-Fehlern
export const FormError = styled.p.attrs({
  className: `form-error`,
})``;

// Button- & Link-Styles
export const ButtonBar = styled.div.attrs({
  className: `button-bar`,
})``;

export const ButtonLink = styled(RouterDomLink).attrs({
  className: `button-link`,
})``;

export const RouteLink = styled(RouterDomLink).attrs({
  className: `link-router`,
})``;

export const Button = styled.button.attrs(({ small, type }) => {
  let typeClass;
  switch (type) {
    case 'secondary':
      typeClass = 'button-type-secondary';
      break;
    case 'link':
      typeClass = 'button-type-link';
      break;
    default:
      typeClass = 'button-type-default';
      break;
  }

  return {
    className: `button ${typeClass}
    ${small ? 'button-size-small' : ''}`,
  };
})``;

/* Tabellen
   ========================================================================== */

export const Table = styled.div.attrs({
  className: `table`,
})``;

export const TableHead = styled.div.attrs({
  className: `table-head`,
})``;

export const TableRow = styled.div.attrs({
  className: `table-row`,
})``;

export const TableCell = styled.div.attrs({
  className: `table-cell`,
})``;

/* Sonstiges
   ========================================================================== */

export const Spacer = styled.div.attrs({
  className: `spacer`,
})``;

export const Icon = styled.i.attrs({
  className: `material-icons icon`,
})``;

export const Highlight = styled.span.attrs({
  className: `highlight`,
})``;

export const Grid = styled.div.attrs({
  className: `grid`,
})``;

export const GridCell = styled.button.attrs({
  className: `grid-cell`,
})``;

export const Spinner = styled.svg.attrs({
  className: `spinner`,
})``;

export const TermsAndConditions = styled.div.attrs({
  className: `terms-and-conditions`,
})``;
