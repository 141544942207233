import { post } from 'utilities';
import auth from '../login';

export function confirm(email, password_reset_token, password) {
  return (dispatch) => {
    post('/api/password-reset/confirm', { email, password_reset_token, password })
      .then(() => {
        dispatch(auth.actions.login(email, password));
      })
      .catch();
  };
}
