import * as t from './actionTypes';

const initialValue = {
  requested: false,
  error: null,
  categories: [],
  activeCategory: null
};

export default function categoryReducer(state = initialValue, action) {
  switch (action.type) {
    case t.FETCH_CATEGORIES_REQUESTED:
      return {
        ...state,
        requested: true
      };
    case t.FETCH_CATEGORIES_COMPLETE:
      return {
        ...state,
        requested: false,
        categories: action.data.categories
      };
    case t.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        requested: false,
        error: action.error.message
      };
    case t.ADD_CATEGORY:
      return { ...state, categories: [...state.categories, action.category] };
    case t.UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map(category =>
          category.id === action.id ? { ...category, name: action.name } : category
        )
      };
    case t.DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(category => category.id !== action.id)
      };
    case t.SORT_CATEGORIES:
      return {
        ...state,
        categories: state.categories.map(category => ({
          ...category,
          sort: action.sort[category.id]
        }))
      };
    default:
      return state;
  }
}
