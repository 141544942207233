import { useDispatch, useSelector } from 'react-redux';
import { useWindowWidth } from 'utilities';
import { SideNavLink, NavIcon } from './Components';
import SvgScholariLogo from 'assets/SvgScholariLogo';
import SvgScholariLogoSmall from 'assets/SvgScholariLogoSmall';
import auth from '../application/login';

function Navigation() {
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const expanded = windowWidth >= 1400;
  const accountType = useSelector((state) => state.user.accountType);

  return (
    <nav className="navigation">
      <div>
        {expanded ? (
          <SvgScholariLogo className="py-6 px-6 h-32 w-full" />
        ) : (
          <SvgScholariLogoSmall className="py-6 px-6 w-full" style={{ height: '4.5rem' }} />
        )}

        <SideNavLink to="/student" exact title="Zeugnisgenerator">
          <NavIcon>post_add</NavIcon>
          {expanded && 'Zeugnisgenerator'}
        </SideNavLink>

        {'Lehrer Schule' !== accountType ? (
          <SideNavLink to="/categories" title="Beurteilungen">
            <NavIcon>list_alt</NavIcon>
            {expanded && 'Beurteilungen'}
          </SideNavLink>
        ) : (
          ''
        )}
        {'Schule' === accountType || 'Große Schule' === accountType ? (
          <div>
            <SideNavLink to="/teachers" exact title="Lehrkräfte">
              <NavIcon>people</NavIcon>
              {expanded && 'Lehrkräfte'}
            </SideNavLink>

            <SideNavLink to="/teachers/invite" title="Lehrkraft einladen">
              <NavIcon>mail</NavIcon>
              {expanded && 'Lehrkraft einladen'}
            </SideNavLink>
          </div>
        ) : (
          ''
        )}

        {'Lehrer Schule' !== accountType ? (
          <SideNavLink to="/account" title="Account">
            <NavIcon>account_circle</NavIcon>
            {expanded && 'Account'}
          </SideNavLink>
        ) : (
          ''
        )}

        <button
          className="hover:text-green-700 py-3 px-6 block flex items-center w-full focus:outline-none"
          onClick={() => dispatch(auth.actions.logout())}
        >
          <NavIcon>meeting_room</NavIcon>
          {expanded && 'Abmelden'}
        </button>
      </div>
      <div>
        <a
          className="hover:text-green-700 py-3 px-6 block flex items-center w-full focus:outline-none"
          href="mailto:support@scholari.de"
        >
          <NavIcon>help</NavIcon>
          {expanded && 'Hilfe'}
        </a>
      </div>
    </nav>
  );
}

export default Navigation;
