import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAssessments, updateAssessment, addAssessment, sortAssessments, deleteAssessment } from './actions';
import {
  Draggable,
  Spacer,
  InputText,
  Button,
  ButtonBar,
  InputButtonGroup,
  InputButtonGroupOption,
  InputSelect,
  Icon,
  Loader,
} from 'modules/components';

export default function AssessmentList({ match }) {
  const sectionId = match.params.id;
  const dispatch = useDispatch();
  const useFetching = () => {
    useEffect(() => {
      dispatch(fetchAssessments(sectionId));
    }, []);
  };
  dispatch(useFetching);

  const isLoading = useSelector((state) => state.assessment.requested);
  const section = useSelector((state) => state.assessment.section);
  const assessments = useSelector((state) => state.assessment.assessments.sort((a, b) => a.sort - b.sort));
  const category = useSelector((state) => state.section.category);
  const grades = useSelector((state) => state.generator.grades);
  const [editable, setEditable] = useState(false);

  function confirmDeleteAssessment(id) {
    if (window.confirm('Sind Sie sich sicher?')) {
      dispatch(deleteAssessment(id));
    }
  }

  function updateAssessmentOrder(assessments) {
    const sort = assessments.reduce((sort, assessment, index) => ({ ...sort, [assessment.id]: index }), {});
    dispatch(sortAssessments(sort));
  }

  function cancelOnClick() {
    window.history.back();
  }

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <section>
        <h1>
          {category.year} {'>'} {category.name} {'>'} {section.name}
        </h1>

        <p>Verwende # als Platzhalter für den Namen</p>
        <Spacer />
        <h2>Beurteilungen</h2>
        <Draggable
          id="assessments"
          items={assessments}
          onReorder={updateAssessmentOrder}
          render={(assessment, provided) => (
            <div key={assessment.id} ref={provided.innerRef} {...provided.draggableProps} className="dnd-element">
              <Icon className="dnd-icon" {...provided.dragHandleProps}>
                drag_handle
              </Icon>{' '}
              <div key={assessment.id} className="flex flex-grow">
                <InputText
                  className="dnd-text"
                  value={assessment.text}
                  onChange={(e) =>
                    dispatch(updateAssessment(assessment.id, assessment.gender, e.target.value, assessment.grade))
                  }
                />
                <InputButtonGroup className="assessment-group">
                  <InputButtonGroupOption
                    active={assessment.gender === 'männlich'}
                    onClick={() =>
                      dispatch(updateAssessment(assessment.id, 'männlich', assessment.text, assessment.grade))
                    }
                  >
                    männlich
                  </InputButtonGroupOption>
                  <InputButtonGroupOption
                    active={assessment.gender === 'weiblich'}
                    onClick={() =>
                      dispatch(updateAssessment(assessment.id, 'weiblich', assessment.text, assessment.grade))
                    }
                  >
                    weiblich
                  </InputButtonGroupOption>
                  <InputButtonGroupOption
                    active={assessment.gender === 'neutral'}
                    onClick={() =>
                      dispatch(updateAssessment(assessment.id, 'neutral', assessment.text, assessment.grade))
                    }
                  >
                    neutral
                  </InputButtonGroupOption>
                </InputButtonGroup>
                <InputSelect
                  value={assessment.grade}
                  onChange={(e) =>
                    dispatch(updateAssessment(assessment.id, assessment.gender, assessment.text, e.target.value))
                  }
                >
                  {grades.map((grade) => (
                    <option key={grade} value={grade}>
                      {grade}
                    </option>
                  ))}
                </InputSelect>
                {editable && (
                  <button onClick={() => confirmDeleteAssessment(assessment.id)} className="dnd-button">
                    Löschen
                  </button>
                )}
              </div>
            </div>
          )}
        />
        <ButtonBar>
          <Button onClick={cancelOnClick} type="secondary">
            Zurück
          </Button>
          <Button
            onClick={() =>
              dispatch(
                addAssessment(
                  sectionId,
                  'männlich',
                  '',
                  grades[0],
                  assessments.reduce((carry, assessment) => Math.max(carry, assessment.sort), 0) + 1
                )
              )
            }
          >
            Neue Beurteilung
          </Button>
          <Button onClick={() => setEditable(!editable)}>Bearbeiten</Button>
        </ButtonBar>
      </section>
    );
  }
}
