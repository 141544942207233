import * as DND from 'react-beautiful-dnd';

/*
<Draggable
  id="categories"
  items={categories}
  onReorder={updateCategoryOrder}
  render={(category, provided) => (
    <div
      key={category.id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      className="flex items-center py-2"
    >
      <Icon className="mr-2 cursor-pointer" {...provided.dragHandleProps}>
        drag_handle
      </Icon>
      <Link className="text-lg">{category.name}</Link>
    </div>
  )}
/>
*/

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export default function Draggable({ id, items, onReorder, render }) {
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    onReorder(reorder(items, result.source.index, result.destination.index));
  }

  return (
    <DND.DragDropContext onDragEnd={onDragEnd}>
      <DND.Droppable droppableId={id}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <DND.Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => render(item, provided)}
              </DND.Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </DND.Droppable>
    </DND.DragDropContext>
  );
}
