import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { registration } from './actions';
import { useDispatch, useSelector } from 'react-redux';

import {
  InputGroup,
  InputText,
  Button,
  InputButtonGroup,
  InputButtonGroupOption,
  InputError,
  FormError,
  TermsAndConditions,
  RouteLink,
  Row,
} from 'modules/components';

export default function RegisterPage() {
  const isError = useSelector((state) => state.register.error);
  const dispatch = useDispatch();
  const [accountType, setAccountType] = useState('Lehrer');
  const { handleSubmit, register, errors } = useForm();

  function onSubmit(values) {
    dispatch(registration(values.email, values.password, accountType));
  }

  return (
    <section className="section-unauth">
      <h1>Registrierung</h1>
      <p style={{ marginTop: '10px' }}>
        Teste Scholari für <b>14 Tage kostenlos und unverbindlich</b>. Es werden{' '}
        <b>keine zahlungs- und personenbezogenen Daten</b> benötigt. <b>Testdaten</b> liegen für das Halbjahr 1.2 vor.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup error={errors.email}>
          <label htmlFor="email">E-Mail-Adresse</label>
          <InputText
            id="email"
            name="email"
            type="text"
            autoFocus
            ref={register({
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Bitte gib eine gültige E-Mail-Adresse ein',
              },
              required: 'Bitte gib eine E-Mail-Adresse ein.',
            })}
          />
          <InputError>{errors.email && errors.email.message}</InputError>
        </InputGroup>

        <InputGroup error={errors.password}>
          <label htmlFor="password">Passwort</label>
          <InputText
            id="password"
            name="password"
            type="password"
            ref={register({
              required: 'Bitte gib ein Passwort ein.',
            })}
          />
          <InputError>{errors.password && errors.password.message}</InputError>
        </InputGroup>

        <InputGroup>
          <label>Account</label>
          <InputButtonGroup>
            <InputButtonGroupOption
              key={'Referendar'}
              active={'Referendar' === accountType}
              onClick={() => setAccountType('Referendar')}
            >
              Referendare
            </InputButtonGroupOption>
            <InputButtonGroupOption
              key={'Lehrer'}
              active={'Lehrer' === accountType}
              onClick={() => setAccountType('Lehrer')}
            >
              Lehrer
            </InputButtonGroupOption>
            <InputButtonGroupOption
              key={'Schule'}
              active={'Schule' === accountType}
              onClick={() => setAccountType('Schule')}
            >
              Schule
            </InputButtonGroupOption>
            <InputButtonGroupOption
              key={'Große Schule'}
              active={'Große Schule' === accountType}
              onClick={() => setAccountType('Große Schule')}
            >
              Große Schule
            </InputButtonGroupOption>
          </InputButtonGroup>
        </InputGroup>

        <TermsAndConditions>
          Mit der Anmeldung akzeptierst du unsere{' '}
          <a className="link" href="https://scholari.de/datenschutz/">
            Datenschutz&shy;erklärung
          </a>{' '}
          und unsere
          <a className="link" href="https://scholari.de/agb">
            {' '}
            AGB
          </a>
          .
        </TermsAndConditions>

        {isError ? <FormError>Die E-Mail-Adresse existiert bereits.</FormError> : ''}

        <Row>
          <Button type="submit">Scholari kostenlos testen</Button>
          <RouteLink to="/login">Du bist bereits registriert?</RouteLink>
        </Row>
      </form>
    </section>
  );
}
